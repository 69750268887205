import React from 'react';
import {
  Actions,
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import CardSecure from '../../assets/images/icons/card-secure.svg';
import DataSecurity from '../../assets/images/icons/data-security.svg';
import FastArrows from '../../assets/images/icons/fast-arrows.svg';
import Shield from '../../assets/images/icons/shield.svg';
import SmartDevice from '../../assets/images/icons/smart-device.svg';
import Thumbprint from '../../assets/images/icons/thumbprint.svg';
import Audit from '../../assets/images/icons/audit.svg';
import Touch from '../../assets/images/icons/touch.svg';
import ShieldCheck from '../../assets/images/icons/shield-check.svg';

const Factor = () => {
  return (
    <PageLayout>
      <SEO
        title="Factor - Identity and Data Management for the Digital Era"
        description="Increase security, reduce your risk of holding customer data, and increase customer engagement."
      />
      <Layout dark background="blue-dark-fade purple-effect">
        <Hero
          type="code"
          backgroundImage="https://images.dragonchain.com/texture/clouds-blue@2x.jpg"
          video={{
            url: 'https://www.youtube.com/watch?v=_6Mzv1CPMXQ',
            autoPlay: true,
          }}
        >
          <Title color="white">Identity and Data Management as it Should be in a Digital Era</Title>
          <Description color="blue-light">
            <p>
              Increase security, reduce your risk of holding customer data, and increase customer engagement.
            </p>
            <Actions
              actions={[
                {
                  type: 'link',
                  name: 'Contact Us',
                  color: 'blue',
                  hover: 'blue-dark',
                  to: '/contact-us',
                },
              ]}
            />
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: ShieldCheck,
                title: 'Privacy',
                body:
                  'GDPR and CCPA capable. Decentralized identity management to reduce liability and protect the identity of your customers and employees.',
              },
              {
                Icon: DataSecurity,
                title: 'Atomic & Granular',
                body:
                  'A Factor can be any piece of information about an identity such as; name, address, SSN, birth date, citizenship, etc.',
              },
              {
                Icon: Audit,
                title: 'Integrate Identity Providers',
                body:
                  'Our partner MyFii provides a full set of verifications, but any other provider can be integrated.',
              },
              {
                Icon: CardSecure,
                title: 'Re-use & Monetize',
                body:
                  'Reuse of Factors by the individual will pay fees to the company that paid for the original verification.',
              },
              {
                Icon: Thumbprint,
                title: 'Proof of Human',
                body:
                  'Verifiable and measurable proof that a user is a specific person and not a bot or look-alike.',
              },
              {
                Icon: Touch,
                title: 'Intuitive Recovery Process',
                body: 'Grandma safe. No technical knowledge necessary.',
              },
              {
                Icon: Shield,
                title: 'Reduce Data Breach Risk',
                body:
                  'Customers personal data is not stored in a large centralized database, reducing costs, risks, and legal liabilities.',
              },
              {
                Icon: FastArrows,
                title: 'Fast API integration',
                body: 'Full Factor RESTful API documentation is available upon request.',
              },
              {
                Icon: SmartDevice,
                title: 'Smart device identity',
                body: 'Assign identity to IoT devices, and integrate other handheld, mobile, and sensors.',
              },
            ]}
          />
          <Row auto span={12} gap="large">
            <Column>
              <Section
                caption="Technology Overview."
                title="Using blockchain technology for identity and data management."
              >
                <p>
                  As enterprises continuously digitize their businesses, there is an increasing need for
                  security and trust built around digital identities. Current identification systems are
                  siloed and enterprises are having major challenges to manage Personal Identifiable
                  Information (PII) in an increasingly regulated environment (eg. GDPR). On top of that,
                  privacy and control of their data is becoming a greater concern for users as well. Factor is
                  using blockchain technology that allows users to authenticate to a website or other service
                  with a secure cryptographic signing mechanism. No username or password will be necessary or
                  required for the sign in process.
                </p>
                <br />
                <p>
                  Factor is a blockchain based identity and access solution that allows users to take control
                  of their identity and eliminates the risk for enterprises to store PII (e.g. risk of data
                  breaches, and GDPR compliance). Delivered as a service, built on top of the Dragonchain
                  platform, Factor Identity authenticates users to applications using public key encryption.
                  Third party Factor Identity Providers confirm claims or factors about a user without sharing
                  the actual data, it de-risks the storage of PIIs associated with users and data breaches.
                </p>
              </Section>
            </Column>
          </Row>
          <Row gap="small">
            <Column span={12}>
              <Section subtitle="Identity" title="Data Management" caption="Reduce cost, time, and risks">
                This identity solution does not store personal identifiable information, instead, the hashes
                of the actions are recorded into our decentralized system. This makes it virtually impossible
                to hack all of the business data because it’s not stored in one location. It also provides
                better access control and management to the data. Individuals or companies can control who has
                the ability to view and edit data, reducing unauthorized access. Factor provides a better
                solution than what federated identity provides: businesses can manage and update a person’s
                consumable identity components or factors into one place. The verification process of Factor
                relies on third party Factor Identity Providers who are able to verify sensitive data for
                businesses. Factor has logic built into its system that can update their data once a Factor
                Identity Provider verifies the data. This can create ease and reduce costs for businesses and
                end users, for example in handling KYC between multiple banks for the same customer.
              </Section>
            </Column>
          </Row>
          <Row gap="large" auto span={4}>
            <Column>
              <Section>
                <ul>
                  <li>
                    Determine where data will be stored, including the geographic region, and allow for
                    tighter control over jurisdictions where nodes are operated.
                  </li>
                  <li>
                    Keep data private and ensure it never leaves the private blockchain unless explicitly
                    granted, or by including references to the underlying data (stored elsewhere) in the form
                    of a hash value in the payload.
                  </li>
                  <li>
                    Comply with requests to be forgotten. This is accomplished by removing the underlying data
                    (“off-chain”) reference via a hash value.
                  </li>
                  <li>
                    Filter access control to applications, providing selective exposure of specific factors.
                  </li>
                </ul>
              </Section>
            </Column>
            <Column>
              <Section>
                <ul>
                  <li>Eliminate liabilities, such as the retention of PII or other sensitive data. </li>
                  <li>Prove you are over 21 without exposing your full Drivers License or Passport.</li>
                  <li>Prove you reside in a particular region without exposing your address.</li>
                  <li>Prove you have a valid email, without exposing the address.</li>
                  <li>
                    Replace identifiers such as a phone number or email address which were never designed to
                    be safely used for logins
                  </li>
                  <li>
                    Identification of devices (e.g. IoT devices) for trusted communication or firmware
                    updates.
                  </li>
                </ul>
              </Section>
            </Column>
            <Column>
              <Section>
                <ul>
                  <li>
                    Verify user’s information while eliminating the risk or exposure to too much personally
                    identifiable information.
                  </li>
                  <li>
                    Managing healthcare records with easy “transportability” without moving the data,
                    conducting decentralized clinical trials.
                  </li>
                  <li>
                    Easily implement a decentralized login or authentication system for any online login
                    application (web, banks, social media, governments, universities).
                  </li>
                  <li>
                    Access control based on one or multiple biometric factors (e.g. fingerprint, iris, facial
                    recognition).
                  </li>

                  <li>SIM Swapping prevention.</li>
                </ul>
              </Section>
            </Column>
          </Row>

          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="In ‘simple’ terms, how does Factor work?">
                  Factor is a decentralized identity &amp; data management solution built on top of
                  Dragonchain’s blockchain technology. With a primary focus on how privacy and control of
                  users’ data can be better handled in this digital world.
                </Accordion.Item>
                <Accordion.Item title="Can you give me an example of why this technology is important?">
                  Factor is a decentralized identity service for applications and services provided by your
                  organization. It deploys a decentralized authentication model, for example to allow users to
                  own (parts of) their data and control who has access to it. This reduces the risks and
                  liability of data breaches.
                </Accordion.Item>
                <Accordion.Item title="How can Factor be used for alternatives to centralized login authentication requests?">
                  Auth is a protocol used by websites and developers to authorize data access from a third
                  party system. This model relies on a central authority (OAuth providers, e.g.: Google,
                  Facebook) which must be fully trusted to not give out data without your consent. Factor
                  allows users to own their data and maintain privacy by providing an additional layer of
                  abstraction between personal information and the system requesting it. Replace centralised
                  login services in the likes of Facebook or Google with secure and decentralized Factor
                  authentication. Factor Authentication allows users to own their data and maintain privacy by
                  providing an additional layer of abstraction between personal information and the system
                  requesting it. We call this abstraction of a Factor, and it’s stored on an immutable
                  blockchain in a way that is verifiable, without exposing the data itself. Factor
                  Authentication does not own your information, you do. No one can access your data unless you
                  explicitly give it to them.
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Try Factor Today">
                Secure your customer identities with Factor. Contact our sales team to learn more about
                integrating Factor into your platform or application.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Factor;
